/* RotatingBadge.css */
.rotating-badge-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid transparent;
  }
  
  .rotating-badge {
    font-size: 24px;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    animation: rotateY 5s infinite linear; /* Adjust the duration as needed */
    width: 300;
    height: 300;
    /* transform-style: preserve-3d; */
    /* perspective: 1000px; Adjust the perspective for more or less depth */
  }
  .rotating-badge:hover{
    animation: none;
  }
  
  @keyframes rotateY {
    from {
      transform: rotateY(0deg) translateZ(0);
    }
    to {
      transform: rotateY(360deg) translateZ(100px); /* Adjust the translateZ for width */
    }
  }
  