.main-page {
    /* border: 1px solid red; */
    background-color: #5e17eb;
    width: 100%;
    margin-top: -1.5rem;
    display: flex;
    /* padding-bottom: 100px; */
    flex-direction: column;
    justify-content: space-between;
    /* padding-top: 60px; */
}
.main-page-header h1{
    /* border: 1px solid red; */
    text-align: start;
    color: white;
    font-size: 3rem;
    font-weight: 600;
    font-family: sans-serif;

}

.main-content {
    /* border: 1px solid red; */
    width: 100%;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
}

.main-content h2 {
    text-align: start;
    margin-left: 2rem;
    color: #fff;
    font-weight: 600;
    font-size: 2rem;
    font-family: sans-serif;
}

.main-content .main-content-email {
    /* border: 1px solid red; */
    width: 50%;
    text-align: start;
    padding-left: 30px;
}
.main-content .main-content-password {
    /* border: 1px solid red; */
    width: 50%;
    text-align: start;
    padding-left: 30px;
}
.main-content .main-content-password .input2{
      height: 40px;
        width: 80%;
        padding-left: 8px;
        border: none;
        border-radius: 12px;
        background: linear-gradient(to top left, #6A58C6, #a8f5ff) !important;
        margin-top: 10px;
        /* padding-left: 10px; */
        /* margin-left: 2rem;
        margin-right: 2rem; */
        font-size: 1.5rem;
        font-family: sans-serif;
        font-weight: 600;
        color: black;
        background-color: #fff;
}
.main-content .main-content-password .btn-eye {
    
    /* border: 1px solid red; */
    height: 40px;
    position: relative;
    right: 30px;
    top: 3px;
    text-align: end;
    display: flex;
    align-items: end;
    justify-content:center;
}
.main-content .input1 {
    height: 40px;
    width: 80%;
    padding-left: 8px;
    border: none;
    border-radius: 12px;
    background: linear-gradient(to top left, #6A58C6, #a8f5ff) !important;
    margin-top: 10px;
    /* padding-left: 10px; */
    /* margin-left: 2rem;
    margin-right: 2rem; */
    font-size: 1.5rem;
    font-family: sans-serif;
    font-weight: 600;
    color: black;
    background-color: #fff;
}

.main-content Button {
    /* border: 1px solid red; */
    margin-top: 5px;
    border-radius: 12px;
    height: 40px;
    width: 9rem;
}

.main-content input:focus {
    outline: none;
}

.main-content .admin-emails {
    /* border: 1px solid red; */
    display: flex;
    margin-top: 1rem;
}

.main-content .admin-emails h2 {
    width: 50%;
    padding-left: 8px;
    height: 40px;
    border: none;
    border-radius: 12px;
    background: linear-gradient(to top left, #6A58C6, #a8f5ff) !important;
    margin-top: 10px;
    /* padding-left: 10px; */
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    font-size: 1.5rem;
    font-family: sans-serif;
    font-weight: 600;
    color: black;
    background-color: #fff;
}

.main-content .admin-emails .btns-admin {
    display: flex;
    /* border: 1px solid red; */
    justify-content: center;
    padding-top: 10px;
}

.side-bar {
    /* border: 1px solid red; */
    height: 80vh;
    width: 13rem;
    position: sticky;
    top: 0px;
    background: linear-gradient(to top left, #6A58C6, #a8f5ff) !important;
   

}

.side-bar .data {
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.side-bar .data p {
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1rem;
}

a {
    text-decoration: none;
}

.email-template {
    /* border:1px solid red; */
    display: flex;
    justify-content: space-between;
}

.email-template .email-template-data {
    width: 60%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    padding-left: 5rem;
}

.email-template .email-template-data h2 {
    text-align: start;
    color: #fff;
    font-weight: 600;
    font-size: 2rem;
    font-family: sans-serif;
}

.email-template .email-template-data .subject::placeholder {
    text-align: left;
}

.email-template .email-template-data .subject {
    width: 100%;
    height: 40px;
    padding-left: 8px;
    border: none;
    border-radius: 12px;
    background: linear-gradient(to top left, #6A58C6, #a8f5ff) !important;
    margin-right: 2rem;
    font-size: 1.5rem;
    font-family: sans-serif;
    font-weight: 600;
    color: black;
    background-color: #fff;
}

.email-template .email-template-data .subject:focus {
    outline: none;
}

.email-template .email-template-data .Button_Name::placeholder {
    text-align: left;
}

.email-template .email-template-data .Button_Name {
    width: 80%;
    height: 40px;
    padding-left: 8px;
    border: none;
    margin-top: 1rem;
    border-radius: 12px;
    background: linear-gradient(to top left, #6A58C6, #a8f5ff) !important;
    margin-right: 2rem;
    font-size: 1.5rem;
    font-family: sans-serif;
    font-weight: 600;
    color: black;
    background-color: #fff;
}

.email-template .email-template-data .Button_Name:focus {
    outline: none;
}

.email-template .email-template-data .message::placeholder {
    text-align: left;
}

.email-template .email-template-data .message {
    margin-top: 1rem;
    width: 100%;
    padding-left: 8px;
    padding-top: 10px;
    border: none;
    border-radius: 12px;
    /* background: linear-gradient(to top left, #6A58C6, #a8f5ff) !important; */
    /* margin-right: 2rem; */
    font-size: 1.5rem;
    font-family: sans-serif;
    font-weight: 600;
    color: black;
    background-color: #fff;
}

.email-template .email-template-data .message:focus {
    outline: none;
}

#button-template-save {
    width: 10rem;
    margin-top: 1rem;
    border-radius: 10px;
    font-size: 1.2rem;
    color: black;
    font-weight: 600;
    font-family: sans-serif;

}

.email-template .upload-exl {
    width: 40%;
    /* border: 1px solid red; */
    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.email-template .upload-exl .circle {
    /* border: 1px solid red; */
    width: 5rem;
    background: linear-gradient(to top left, #6A58C6, #a8f5ff) !important;
    height: 5rem;
    border-radius: 50%;
    margin-top: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
 .circle {
    /* border: 1px solid red; */
    width: 5rem;
    background: linear-gradient(to top left, #6A58C6, #a8f5ff) !important;
    height: 5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.email-template .upload-exl .circle .upload-icon {
    font-size: 4rem;
    color: #fff;
}
.upload-icon {
    font-size: 4rem;
    color: #fff;
}

.email-template .upload-exl .upload-button {
    /* border:1px solid red; */
    margin-top: 1rem;
    width: 10rem;
    text-align: center;
}
.upload-button {
    /* border:1px solid red; */
    margin-top: 1rem;
    width: 10rem;
    text-align: center;
}