/* *{
    margin: 0;
    padding: 0;
    background-color: black;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 20px
  } */
/* .website_announcement{
    text-align: center;
    padding-top: 10px;
    font-size: 40px;
  } */
  .announcement_panel {
    display: flex;
    justify-content: space-evenly;
    background-color: black;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 20px;
    /* height: 100vh; */
    height: '100%'
  }
  .created_announcement {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    overflow-wrap: break-word;
    width: '40%'
  }
  ::placeholder {
    text-align: center;
    color: black;
  }
  #edit_icon {
    margin-left: 20px;
    margin-top: 20px;
  }
  input:focus::placeholder {
    color: transparent;
  }
  
  button:hover {
    cursor: pointer;
  }
  #announcments_heads {
    text-decoration: underline;
    text-align: center;
  }
  #announcments_head_2 {
    text-decoration: underline;
    /* margin-right: 250px; */
  }
  
  .create_announcement {
    display: flex;
    flex-direction: column;
  }
  
  .create_announcement input {
    margin-top: 10px;
    background-color: white;
    color: black;
    height: 35px;
  }
  .input_details_ann {
    height: 50px;
  }
  
  .show_announcement {
    align-items: center;
    margin-top: 20px;
    overflow-wrap: break-word;
    width: 60%;
    text-align: left;
  }
  #submit_ann {
    background-color: cyan;
    height: 30px;
    margin-top: 20px;
    color: black;
  }
  
  #submit_community{
    background-color: #60F2D6;
    height: 30px;
    margin-top: 20px;
    color: black;
  }
  
  #heading_announcement {
    color: #9a4cdf;
    text-decoration: underline;
  }
  
  #heading_announcement_link {
    text-align: start;
    margin-top: 0;
  }
  #announcments_heads_3 {
    background-color: white;
    color: black;
    text-decoration: underline;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .update_announcement {
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: white !important;
    text-align: center;
  }
  .update_announcement input {
    margin-top: 10px;
    background-color: rgb(210, 210, 210);
    color: black;
    height: 30px;
  } 
  .update_announcement select {
    margin-top: 10px;
    background-color: rgb(210, 210, 210);
    color: black;
    height: 30px;
    margin:1%;
  }
  .ann_pop_main_page {
    position: absolute;
    z-index: 1;
    right: 30px;
    top: 50px;
    display: flex;
    flex-direction: row;
    color: white !important;
  }
  .ann_pop_main_content {
    background-color: #9a4cdf;
    width: 300px;
    min-height: 10px;
    height: auto;
    border-radius: 10px;
    padding: 4px 2px 2px 4px;
  }
  #main_page_pop_icon {
    padding: 10px;
  }
  #main_page_pop_heading {
    color: yellow;
    font-weight: 700;
    font-size: 18px !important;
    text-decoration: underline;
    background-color: #9a4cdf;
  }
  #main_page_pop_text {
    background-color: #9a4cdf;
    font-size: 15px !important;
  }
  
  @media screen and (max-width: 420px) {
    .announcement_panel {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: scroll;
    }
    .create_announcement {
      width: 80%;
      margin-left: 40px;
      overflow: hidden;
    }
    .show_announcement {
      width: 80%;
      margin-left: 40px;
      text-align: center;
      justify-content: center;
      align-items: center;
      overflow: scroll;
    }
    .create_announcement {
      width: 80%;
    }
  }