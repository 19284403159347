.Login_Form {
  /* margin-top: 5rem;
    margin-bottom: 2rem;
    font-family: 'Open Sans', sans-serif!important; */
  padding-top: 3% !important;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 4.6%;
  background: linear-gradient(to top left, #52efda 0%, #8dffcc 100%) !important;
  overflow-y: hidden;
}

.left_box {
  /* background-color: green; */
  width: 100%;
}


.right_box {
  width: 100%;
  /* background-color: blueviolet; */
}
.login_form {
  background-color: #bfffe3;
  width: 60%;
  margin: auto;
  margin-top: 5%;
  padding: 5%;
  box-shadow: -4px 5px grey;
}

.login_form > h3 {
  text-align: center;
}

.auth_button {
  cursor: pointer !important;
  width: 50%;
}

.error {
  color: red;
}

.Box {
  margin-top: 4rem;
}

.not_member {
  font-size: 0.8rem !important;
}

@media only screen and (max-width: 768px) {
  .Login_Form {
    padding-bottom: 20%;
  }
  .login_form {
    width: 90%;
  }
}

@media only screen and (max-width: 420px) {
  .Login_Form {
    padding-bottom: 25%;
  }
  .login_form {
    width: 90%;
  }
}

@media only screen and (max-width: 320px) {
  .Login_Form {
    padding-bottom: 30%;
  }
}

/* to whover editing the code later */
/*I did not write the confusing classnames*/