.popup .overlay{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    z-index: 1;
    
}

.popup .content{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    width: 50%;
    height: 50%;
    z-index: 2;
    text-align: center;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.popup .close-btn{
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    background: #222;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}
.popup .btn_box{
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.popup .email_btn1{
    margin-right: 50px;
}
@media screen and (max-width: 750px) {
    .popup .content {
        width: 90%;
        margin: 100px 20px 0px 4px;
    }
}

@media screen and (max-width: 480px) {
.popup .content {
       width: 90%;
       margin: 100px 20px 0px 4px;
    }
}
@media screen and (max-width: 360px) {
.popup .email_btn1 {
        margin-right: 0px;
    }
        .popup .btn_box{
            flex-direction: column;
        }
                .popup .email_btn1 {
                    margin-bottom: 50px;
                }
}

